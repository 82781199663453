
import { clone } from 'ramda';
import { Vue, Component } from 'vue-property-decorator';
import moment from 'moment';
import Thumbnail from '@/components/Thumbnail.vue';
import ClassCarousel from '@/components/ClassCarousel.vue';
import RecentReviews from '@/components/RecentReviews.vue';
import { Meta } from '@/misc/decorators';
import { Teachers } from '@/store/modules';

@Component({
  name: "TeacherView",
  components: {
    Thumbnail,
    RecentReviews,
    ClassCarousel,
  }
})
export default class extends Vue {
  isLoading = true;
  error = '';
  model: any = {};
  classes: any = [];
  totalClasses = 0;
  students = 0;

  @Meta
  metaInfo() {
    return {
    title: `Explore all classes by ${this.model.name} | SkillToon`,
    link: [
      { rel: 'canonical', href: `https://skilltoon.com/teachers/${this.slug}` }
    ],
    meta: [
      { property: 'og:url', content: `https://skilltoon.com/${this.slug}` },
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: this.model.name ? `Explore all classes by ${this.model.name} | SkillToon` : 'Explore all classes by Teacher | SkillToon' },
      { property: 'og:description', content:`Explore classes provided by ${this.model.name}, check reviews, select your preference and enroll with quick easy steps.` },
      { property: 'og:image', content: 'https://skilltoon.com/categories_hero.png' },
      { name: 'description', content: this.model.name ? `Explore classes provided by ${this.model.name}, check reviews, select your preference and enroll with quick easy steps.` : 'Explore classes provided by Teacher, check reviews, select your preference and enroll with quick easy steps.'},
      { name: 'keywords', content: this.model.name && this.slug ? `${this.model.name}, classes by ${this.slug}` : 'Teacher, classes by Teacher' },
      { name: 'robots', content: 'index,follow' },
      { property: 'twitter:card', content: 'summary_large_image' },
      { property: 'twitter:url', content: `https://skilltoon.com/${this.slug}` },
      { property: 'twitter:title', content: this.model.name ? `Explore all classes by ${this.model.name} | SkillToon` : 'Explore all classes by Teacher | SkillToon'},
      { property: 'twitter:description', content: this.model.name ? `Explore classes provided by ${this.model.name}, check reviews, select your preference and enroll with quick easy steps.` : 'Explore classes provided by Teacher, check reviews, select your preference and enroll with quick easy steps.' },
      { property: 'twitter:image', content: 'https://skilltoon.com/categories_hero.png' },
    ]
    }
  }


  get saved() {
    return !!this.$route.query.saved;
  }

  get teacherId() {
    return this.model.id;
  }

  get slug() {
    return this.$route.params.slug;
  }

  get experience() {
    return moment(this.model.createdAt).toNow(true);
  }

  async mounted() {
    this.isLoading = true;
    try {
      const response = await Vue.$axios.get(`/teachers/${this.slug}`);
      this.model = clone(response.data);
      await this.getClasses();
      const { totalClasses, totalStudents } = await Teachers.getStatistics({ id: this.model.id });
      this.totalClasses = totalClasses;
      this.students = totalStudents;
    } catch (err: any) {
      this.error = err.message;
    }
    this.isLoading = false;
  }

  sortClasses(classes: any) {
    for (const el of classes) {
      el.meetings.sort((a: any, b: any) => new Date(a.date).valueOf() - new Date(b.date).valueOf());
    }
    classes.sort((a: any, b: any) =>  new Date(a.meetings[0].date).valueOf() - new Date(b.meetings[0].date).valueOf());

    return classes;
  }

  async getClasses() {
    try {
      const res = await this.$axios.get(`/classes?sort=date&sortDesc=true&limit=10&teacherId=${this.teacherId}`);
      const { items } = res.data;
      this.classes = Array.from(clone(this.sortClasses(items))).filter((item: any) => new Date(item.meetings[0].date).toISOString() > new Date().toISOString());
    } catch (err) {
      this.error = err.message;
    }
  }
}
